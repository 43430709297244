<template>
  <div id="app">
    <router-view></router-view>
    
  </div>
</template>
<script>
import "@/vantui";
export default {

};
</script>
<style lang="less" >

</style>
