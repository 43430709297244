<template>
  <div>
    <p class="textsty">API状态</p>
    <hr>
    <textData :chartData="chartData" :title="chartTitle" />
    <textData :chartData="chartData2" :title="chartTitle2" />
    <textData :chartData="chartData3" :title="chartTitle3" />
    <textData :chartData="chartData4" :title="chartTitle4" />
    <textData :chartData="chartData5" :title="chartTitle5" />
    
  </div>
</template>

<script>
  import textData from '../components/common/textData.vue';
export default {
  components: {
    textData,
  },
  data() {
    return {
      chartTitle: '主api',
      chartTitle2: '副api',
      chartTitle3: '备api-1',
      chartTitle4: '备api-2',
      chartTitle5: '备api-3',
      
      chartData: {
        category: "",
        data: [
          { time: 0, status: true },
          { time: 1, status: true },
          { time: 2, status: true },
          { time: 3, status: true },
          { time: 4, status: true },
          { time: 5, status: false },
          { time: 6, status: false },
          { time: 7, status: true },
          { time: 8, status: false },
          { time: 9, status: false },
        ],
      },
      chartData2: {
        category: "",
        data: [
          { time: 0, status: false },
          { time: 1, status: true },
          { time: 2, status: true },
          { time: 3, status: true },
          { time: 4, status: true },
          { time: 5, status: false },
          { time: 6, status: false },
          { time: 7, status: true },
          { time: 8, status: false },
          { time: 9, status: true },
        ],
      },
      chartData3: {
        category: "",
        data: [
          { time: 0, status: false },
          { time: 1, status: true },
          { time: 2, status: true },
          { time: 3, status: true },
          { time: 4, status: true },
          { time: 5, status: false },
          { time: 6, status: false },
          { time: 7, status: true },
          { time: 8, status: false },
          { time: 9, status: true },
        ],
      },
      chartData4: {
        category: "",
        data: [
          { time: 0, status: true },
          { time: 1, status: true },
          { time: 2, status: true },
          { time: 3, status: true },
          { time: 4, status: true },
          { time: 5, status: true },
          { time: 6, status: true },
          { time: 7, status: true },
          { time: 8, status: true },
          { time: 9, status: true },
        ],
      },
      chartData5: {
        category: "",
        data: [
          { time: 0, status: false },
          { time: 1, status: false },
          { time: 2, status: false },
          { time: 3, status: false },
          { time: 4, status: false },
          { time: 5, status: false },
          { time: 6, status: false },
          { time: 7, status: false },
          { time: 8, status: false },
          { time: 9, status: false },
        ],
      },
    };
  },
};
</script>
<style scoped>
.textsty {
    margin-top: 20px;
    margin-left: 10px;
    text-align: left;
    color: #2b90d9;
}
hr{
    width: 80px;
    height: 2px;
    margin-left: 10px;
    margin-top: 3px;
    background-color: #2b90d9;
}
</style>
