<template>
    <div id="details_rd_box">
        <div id="details_rd_text_box">
            <p>{{ number }}</p>
            <p>{{ content }}</p>
            <p>{{ title }}</p>
        </div>
        <div id="details_rd_icon_box">
            <van-icon :name="icon" size="40" color="#fff" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'content', 'number', 'icon'],

}
</script>

<style lang="less" scoped>
#details_rd_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    #details_rd_text_box {
        flex: 1;

        :nth-child(1) {
            margin-bottom: 10px;    
            font-size: 26px;
            color: rgb(142, 206, 122);
        }
        :nth-child(2) {
            color: #fff;
            font-size: 16px;
            width: 70px;
        }
        :nth-child(3) {
            margin-top: 5px;
            font-size: 12px;
            width: 70px;
            color: white;
        }
    }

    #details_rd_icon_box {
        flex: 1;
        padding-left: 45px;
        color: white;

    }
}
</style>