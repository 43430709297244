<template>
    <div>
        <p class="textsty">营业收入</p>
        <hr>
        <line-chart :options="chartOptions"></line-chart>
        <div id="dataBox">
            <van-grid :column-num="2" gutter="10" id="ys">
                <van-grid-item class="red-bg">
                    <p class="p1">{{ time }}</p>
                    <p class="p2">friday ai</p>
                    <p class="p3">99$</p>
                </van-grid-item>
                <van-grid-item >
                    <p class="p1">{{ time }}</p>
                    <p class="p2">chatfriday</p>
                    <p class="p3">99$</p>
                </van-grid-item>
                <van-grid-item>
                    <p class="p1">{{ time }}</p>
                    <p class="p2">yun ai</p>
                    <p class="p3">99$</p>
                </van-grid-item>
                <van-grid-item>
                    <p class="p1">{{ time }}</p>
                    <p class="p2">总计</p>
                    <p class="p3">99$</p>
                </van-grid-item>
            </van-grid>
        </div>
    </div>
</template>

<script>
//   import LineChart from './components/LineChart.vue';

export default {
    components: {},
    data() {
        return {
            time: "",
            chartOptions: {
                legend: {
                    // 控制图例的显示位置，'bottom' 表示在底部
                    top: "bottom",
                    // 自定义显示名称
                    //   data: ["friday ai", "chatfriday ai", "yun ai"],
                },
                xAxis: {
                    type: "category",
                    data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "friday ai", 
                        data: [82, 93, 90, 93, 129, 133, 132],
                        type: "line",
                        label: {
                            show: true, // 开启标签显示
                            position: "top", // 标签的位置，这里设置为顶部
                            valueAnimation: true, // 是否显示数值的动画效果
                        },
                    },
                    {
                        name: "chatfriday ai", 
                        data: [100, 110, 105, 115, 120, 125, 130], 
                        type: "line",
                        label: {
                            show: true, 
                            position: "top", 
                            valueAnimation: true, 
                        },
                    },
                    {
                        name: "yun ai", 
                        data: [10, 11, 105, 15, 120, 125, 130], 
                        type: "line",
                        label: {
                            show: true, 
                            position: "top", 
                            valueAnimation: true, 
                        },
                    },
                ],
            },
        };
    },
    mounted() {
        this.updateCurrentTime();
    },
    methods: {
        updateCurrentTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, "0"); // 月份是从0开始的
            const day = String(now.getDate()).padStart(2, "0");
            this.time = `${year}-${month}-${day}`;
        },
    },
};
</script>
<style lang="less" scoped>
.textsty {
    margin-top: 20px;
    margin-left: 10px;
    text-align: left;
    color: #2b90d9;
}
hr{
    width: 80px;
    height: 2px;
    margin-left: 10px;
    margin-top: 3px;
    background-color: #2b90d9;
}
p{
    margin-top: 10px;
}

#ys {
    margin-top: 20xp;
    border-radius: 5px;
}
.p3{
    color:red;
    font-size: 20px;
}
::v-deep .van-grid-item:nth-child(1) .van-grid-item__content,
::v-deep .van-grid-item:nth-child(2) .van-grid-item__content {
    background-color: #679cc2;
}

::v-deep .van-grid-item:nth-child(3) .van-grid-item__content,
::v-deep .van-grid-item:nth-child(4) .van-grid-item__content {
    background-color: #4fb0c6;
}

</style>
