<template>
    <div>
        <div>
            
            <div id="msg">
                <p id="rd-title">今日数据 </p>
                <p>{{time}}</p>  
            </div>
            <hr>
            
            <van-grid :column-num="2" gutter="10">
                <van-grid-item class="red-bg">
                    <details-rd title="今日对话" content="Friday" number="998"  icon="chat-o"/>
                </van-grid-item>
                <van-grid-item>
                    <details-rd title="今日注册" content="Friday" number="1090"  icon="manager-o"/>
                </van-grid-item>
                <van-grid-item>
                    <details-rd title="今日对话" content="chatFri" number="88" icon="chat-o"/>
                </van-grid-item>
                <van-grid-item>
                    <details-rd title="今日注册" content="chatFri" number="98" icon="manager-o" />
                </van-grid-item>
                <van-grid-item>
                    <details-rd title="今日对话" content="yun ai" number="298" icon="chat-o" />
                </van-grid-item>
                <van-grid-item>
                    <details-rd title="今日注册" content="yun ai" number="98" icon="manager-o" />
                </van-grid-item>
            </van-grid>
        </div>
    </div>
</template>

<script>
// import detilsRa from './common/details-rd.vue'
import api from "@/api/index.js";

export default {
    data() {
        return {
            data_muber1: '',
            data_muber2: '',
            data_muber3: '',
            data_muber4: '',
            data_muber5: '',
            data_muber6: '',
            time: '',
        }
    },
    components: {
        // detilsRa
    },
    mounted() {
        this.updateCurrentTime(); 
        this.intervalId = setInterval(this.updateCurrentTime, 10000000);

       


    api.getFridayW().then((res) => {
      console.log(res.data);
      
    });
    api.getFridayZ().then((res) => {
    //   console.log(res.data);
      
    });
    api.getChatfridayW().then((res) => {
    //   console.log(res.data);
      
    });
    api.getChatfridayZ().then((res) => {
    //   console.log(res.data);
      
    });
    
  },
  beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId); 
        }
    },
  methods: {
        updateCurrentTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, "0"); 
            const day = String(now.getDate()).padStart(2, "0");
            this.time = `${year}-${month}-${day}`;
        },
    },

}
</script>
<style lang="less" scoped>
::v-deep .van-grid {
    margin-top: 10px;
    // background-color: beige;


    .van-grid-item__content {
        background-color: #C61CCA;
        display: flex;
        border-radius: 10px;

    }

    .van-grid-item__content:nth-child(2) {
        background-color: red;
    }
}


::v-deep .van-grid-item:nth-child(1) .van-grid-item__content,
::v-deep .van-grid-item:nth-child(2) .van-grid-item__content {
    background-color: #2b90d9;
}

::v-deep .van-grid-item:nth-child(3) .van-grid-item__content,
::v-deep .van-grid-item:nth-child(4) .van-grid-item__content {
    background-color: #4fb0c6;
}

::v-deep .van-grid-item:nth-child(5) .van-grid-item__content,
::v-deep .van-grid-item:nth-child(6) .van-grid-item__content {
    background-color: #4f86c6;
}
#rd-title{
    text-align: left;
    color: #2b90d9;
    margin-top: 10px;
    margin-left: 10px;
}
hr{
    width: 80px;
    height: 2px;
    margin-left: 10px;
    margin-top: 3px;
    background-color: #2b90d9;
}
#msg{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    :nth-child(2){
        margin-top: 10px;
        margin-right: 10px;
        color: #2b90d9;
        font-size: 18px;
    }
}
</style>