<template>
    <div id="app2">
  <div id="top-box">
  <p>云之都</p>
  </div>
  <div id="buttom-box">
  <div id="menu">
    <van-icon name="bar-chart-o" size="20" color="red" @click="toggleSidebar" />
    <p style="color:skyblue;">数据中心</p>
    <van-icon name="qr" color="red" size="20" @click="toggleSidebar_2"/>
  </div>
  <div id="side-bar" v-show="isshow" >
    
    <ul>
      <li><router-link to="/">首页</router-link></li>
      <li> <router-link to="dataCenter">数据中心</router-link></li>
      <li><router-link to="apiData">apiStatus</router-link></li>
    </ul>
  <router-view></router-view>
  </div>
  <div id="side-bar-left" v-show="isshowleft" >
    <p>快捷访问</p>
  <ul>
    <li>
      <van-button type="primary" class="btn">
        <a href="">friday</a>
      </van-button>
    </li>
    <li>
      <van-button type="primary" class="btn">
        <a href="">chatfri</a>
      </van-button>
    </li>
    <li>
      <van-button type="primary" class="btn">
        <a href="">yun ai</a>
      </van-button>
    </li>
    <li>
      <van-button type="primary" class="btn">
        <a href="">friday后台</a>
      </van-button>
    </li>
    <li>
      <van-button type="primary" class="btn">
        <a href="">chatfri后台</a>
      </van-button>
    </li>
    <li>
      <van-button type="primary" class="btn">
        <a href="">yun ai后台</a>
      </van-button>
    </li>
  </ul>
  </div>
  <selectionTime />
 
  
  
  </div>
    </div>
  </template>
  
  <script>
  
  import income from '@/components/income.vue';
  import selectionTime from '@/components/selectionTime.vue';
  export default {
    data() {
      return {
        isshow: false,
        isshowleft: false,
        
      };
    },
    components: {
      income,
      selectionTime,
    },
    methods: {
      toggleSidebar(){
        this.isshow = !this.isshow;
        this.isshowleft = false;
      },
      toggleSidebar_2(){
        this.isshowleft = !this.isshowleft;
        this.isshow = false;
      },
      
      
    },
    
  };
  </script>
  
  <style lang="less" scoped>
  #app2 {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 7vh; 
    overflow-y: auto; 
    overflow-x: hidden;
  }
  
  #top-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 7vh;
    background-color: rgb(109, 77, 19);
    z-index: 1000; /* 确保它位于其他元素之上 */
    
    p{
      margin-top: 10px;
      font-size: 20px;
      line-height: 30px;
      color: white;
      
    }
  }
  #buttom-box{
    flex: 1;
    position: relative;
    flex: 1;
    padding-top: 30px;
  }
  #menu{
    position: fixed;
    top: 7vh; /* 与#top-box底部对齐 */
    left: 0;
    width: 100%;
    height: 30px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    :nth-child(1){
      padding-left: 5px;
    }
    :nth-child(2){
      padding-right: 5px;
    }
  }
  #side-bar{
    position: fixed; 
  top: 10vh; 
  left: 0; 
  width: 110px;
  height: 80vh; 
  background-color: #fff;
  z-index: 99;
  border-radius: 0% 10px 10px 0%;
  opacity: 0.9; 
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
  overflow-y: auto;
  transition: all 0.3s ease; 
  }
  #side-bar-left{
    position: fixed; 
  top: 10vh; 
  right: 0; 
  width: 110px;
  height: 80vh; 
  background-color: #fff;
  z-index: 99;
  border-radius: 0% 10px 10px 0%;
  opacity: 0.9; 
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
  overflow-y: auto;
  transition: all 0.3s ease; 
    p{
      margin-top: 10px;
    }
  }
  ul{
    margin-top: 50px;
    li{
      margin-top: 20px;
      width: 100px;
     
    }
  }
  .backtop{
    height: 50px;
    background-color: #65c597;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    margin-top: 20px;
  }
  .btn{
    width: 100px;
  }
  .router-link-active{
    color: red;
  }
  </style>
  