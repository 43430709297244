import axios from "../utils/request"
import path from "./path"

const api = {
   
    getFridayZ(){
        return axios.get(path.baseUrl + path.getFridayZ)
    },
    getFridayW(data){
        return axios.get(path.baseUrl + path.getFridayW)
    },

    getChatfridayW(){
        return axios.get(path.baseUr2 + path.getChatfridayW)
    },
    getChatfridayZ(){
        return axios.get(path.baseUr2 + path.getChatfridayZ)
    },

    apiZt(){
        return axios.get(path.apiZT)
    },
    apiYe(){
        return axios.get(path.apiYE)
    },
}

export default api