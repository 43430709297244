import { render, staticRenderFns } from "./apiStatus.vue?vue&type=template&id=9b9a90f6&scoped=true"
import script from "./apiStatus.vue?vue&type=script&lang=js"
export * from "./apiStatus.vue?vue&type=script&lang=js"
import style0 from "./apiStatus.vue?vue&type=style&index=0&id=9b9a90f6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b9a90f6",
  null
  
)

export default component.exports