<template>
  <div ref="chart"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  watch: {
    options: {
      immediate: true,
      handler(newOptions) {
        if (this.chartInstance) {
          this.chartInstance.setOption(newOptions, true); // 第二个参数为 true 表示不合并 options
        }
      },
    },
  },
  mounted() {
    this.chartInstance = echarts.init(this.$refs.chart);
    this.chartInstance.setOption(this.options);
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
};
</script>

<style scoped>
div {
  width: auto;
  height: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 1px;
}
</style>
