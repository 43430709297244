<template>
  <div>
    <div>
      <p>选择类别</p>
      <van-dropdown-menu>
        <van-dropdown-item v-model="value1" :options="option1" />
      </van-dropdown-menu>
    </div>
    <p>选择时间</p>
    <van-field
      v-model="startTime"
      readonly
      clickable
      placeholder="选择开始日期"
      label="开始日期"
      @click="showStartTimePicker = true"
    />
    <van-field
      v-model="endTime"
      readonly
      clickable
      placeholder="选择结束日期"
      label="结束日期"
      @click="showEndTimePicker = true"
    />
    <van-datetime-picker
      v-model="currentStartDate"
      type="date"
      title="选择日期"
      :min-date="minDate"
      :max-date="maxDate"
      v-show="showStartTimePicker"
      @cancel="showStartTimePicker = false"
      @confirm="handleStartTimeConfirm"
    />
    <van-datetime-picker
      v-model="currentEndDate"
      type="date"
      title="选择日期"
      :min-date="minDate"
      :max-date="endDateMax"
      v-show="showEndTimePicker"
      @cancel="showEndTimePicker = false"
      @confirm="handleEndTimeConfirm"
    />
    <van-button type="info" id="btn"  @click="inquire" >查询</van-button>
    
     <!--在这里加上发送接口操作  -->

    <div id="dataBox" v-show="isshow">
      <ul>
        <li>
          <p>创作数据:</p>
          <p>{{ createData }}</p>
        </li>
        <li>
          <p>注册数据:</p>
          <p>{{ signInData }}</p>
        </li>
        <li>
          <p>收入数据:</p>
          <p>{{ incomeData }}</p>
        </li>
      </ul>
      <line-chart :options="chartOptions" />

      <div id="checkedBox">
    <van-checkbox v-model="checked1" plain shape="square">{{ checked1Data }}</van-checkbox>
    <van-checkbox v-model="checked2" plain shape="square" id="checked2">{{ checked2Data }}</van-checkbox>
    <van-checkbox v-model="checked3" plain shape="square" >{{ checked3Data }}</van-checkbox>
  </div>
    </div>
  </div>
</template>

<script>
import { Field, DatetimePicker } from "vant";
import api from "@/api/index.js";
import LineChart from "./common/LineChart.vue";

export default {
  components: {
    LineChart,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
  },
  data() {
    return {
      checked1Data: "创作数据",
      checked2Data: "注册数据",
      checked3Data: "收入数据",
      //选择数据
      value1: 0,
      option1: [
        { text: "全部", value: 0 },
        { text: "friday Ai", value: 1 },
        { text: "chatfriday Ai", value: 2 },
        { text: "yun Ai", value: 3 },
      ],
      //选择时间数据
      startTime: "",
      endTime: "",
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      showStartTimePicker: false,
      showEndTimePicker: false,
      minDate: new Date(new Date().getFullYear(), 0, 1),
      maxDate: new Date(new Date().getFullYear(), 11, 31),
      endDateMax: new Date(),
      //图表数据

      //渲染数据
      createData: "123",
      signInData: "234",
      incomeData: "345",
      //选择显示
      checked1: 1,
      checked2: 1,
      checked3: 1,

      lineData1: [182, 193, 190, 193, 129, 133, 132],
      lineData2: [100, 110, 105, 115, 120, 125, 130],
      lineData3: [10, 20, 35, 40, 60, 70, 80,100],

      isshow: 1,
      // 控制图表显示
    };
  },
  methods: {
    handleStartTimeConfirm(value) {
      this.startTime = value.toISOString().split("T")[0];
      this.showStartTimePicker = false;
      this.endDateMax = new Date(value.getTime() + 86400000 * 30);
    },
    handleEndTimeConfirm(value) {
      this.endTime = value.toISOString().split("T")[0];
      this.showEndTimePicker = false;
    },
  
   
  },
  computed: {
    chartOptions() {
      const series = [];
      if ((this.checked1)) {
        series.push({
          name: this.checked1Data,
          data: this.lineData1,
          type: "line",
          label: {
            show: true,
            position: "top",
            valueAnimation: true,
          },
        });
      }
      if (this.checked2) {
        series.push({
          name: this.checked2Data,
          data: this.lineData2,
          type: "line",
          label: {
            show: true,
            position: "top",
            valueAnimation: true,
          },
        });
      }
      if (this.checked3) {
        series.push({
          name: this.checked3Data,
          data: this.lineData3,
          type: "line",
          label: {
            show: true,
            position: "top",
            valueAnimation: true,
          },
        });
      }
      return {
        legend: {
          top: "bottom",
        },
        xAxis: {
          type: "category",
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
        yAxis: {
          type: "value",
        },
        series: series,
      };
    },
  },
  mounted() {
    console.log(this.chartOptions);
    let sum = this.lineData1.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let sum2 = this.lineData2.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let sum3 = this.lineData3.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    this.createData = sum;
    this.signInData = sum2;
    this.incomeData = sum3;
  }
};
</script>

<style lang="less" scoped>
p {
  text-align: left;
  margin-top: 18px;
  margin-left: 10px;
  margin-bottom: 5px;
  color: #2b90d9;
}
#btn {
  width: 200px;
  margin-top: 20px;
  border-radius: 5px;
  color: yellow;
  font-size: 18px;
}
ul {
  margin-top: 40px;
  li {
    display: flex;
    font-size: 18px;
    height: 40px;
    // border: #969ea3 1px solid;
    line-height: 10px;
    :nth-child(2) {
      width: 50vw;
      text-align: center;
    }
  }
}
#checkedBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  #checked2 {
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
