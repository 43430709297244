const base = {
    baseUrl:"https://friday.yunzd.cn",
    baseUr2:'https://chatfriday.cn',

    getFridayW:"/kf/selectWds/date",//问答
    getFridayZ:"/kf/NewUser/NewUser",//注册

    getChatfridayW:"/kf/Index/wds",//问答
    getChatfridayZ:"/kf/Index/newuser",//注册

    apiZt:"http://status.yunzd.cn/api/status-page/heartbeat/all",
    apiYe:"https://monitor.yunzd.cn/shuju/index/apiye",
    

}

export default base;