<template>
  <div id="box">
    <div class="title">{{ title }}</div>
    <div
      ref="minuteStatusChart"
      
    ></div>
    <div class="status-text">{{ statusText }}</div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "MinuteStatusChart",
  props: {
    title: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null,
      statusText: "",
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initChart();
        }
      },
      deep: true,
    },
  },
  methods: {
    initChart() {
      if (this.chartInstance) {
        this.chartInstance.dispose();
      }
      this.chartInstance = echarts.init(this.$refs.minuteStatusChart);

      const { category, data } = this.chartData;
      const xAxisData = data.map((item) => `${item.time}分`);
      const seriesData = data.map(() => 1);
      const colors = data.map((item) => (item.status ? "#65c597" : "#2b90d9"));

      this.statusText = data[data.length - 1].status ? "正常" : "异常";

      const option = {
        title: {
          text: `${category} `,
        },

        xAxis: {
          type: "category",
          data: xAxisData,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 1,
          axisLabel: { show: false },
        },
        series: [
          {
            name: category,
            type: "bar",
            data: seriesData,
            itemStyle: {
              color: (params) => colors[params.dataIndex],
            },
          },
        ],
        
      };

      this.chartInstance.setOption(option);
    },
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
};
</script>

<style lang="less" scoped>
#box {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  :nth-child(2) {
    width: 150px;
    height: 15px;
    // margin-left: 30px;
    border: 2px solid #65c597;
    border-radius: 5px;
    margin-right: 15px;
    
  }
  .status-text{
    margin-right: 20px;
    color: #379392;
  }
}
.title {
  color: #4fb0c6;
  width: 70px;
  text-align: left;
  margin-left: 10px;
}
</style>
