<template>
    <div>
        <p class="textsty">api余额</p>
        <hr>
<ul>
    <li>
        <p style="color: #379392;">主渠道</p>
        <van-progress :percentage="number1" stroke-width="16" class="progressBar" color="#379392" />
        <p style="color: #379392;">200/400</p>
    </li>
    <li>
        <p style="color: #4f86c6;">副渠道</p>
        <van-progress :percentage="number2" stroke-width="16" class="progressBar" color="#4f86c6"/>
        <p style="color: #4f86c6;">200/400</p>
    </li>
    <li>
        <p style="color: #4fb0c6;">备用-1</p>
        <van-progress :percentage="number3" stroke-width="16" class="progressBar" color="#4fb0c6"/>
        <p style="color: #4fb0c6;">200/400</p>
    </li>
    <li>
        <p style="color: #6c49b8;">备用-2</p>
        <van-progress :percentage="number4" stroke-width="16" class="progressBar" color="#6c49b8"/>
        <p style="color: #6c49b8;">200/400</p>
    </li>
    <li>
        <p style="color: #417091;">备用-3</p>
        <van-progress :percentage="number5" stroke-width="16" class="progressBar" color="#417091"/>
        <p style="color: #417091;">200/400</p>
    </li>
</ul>

    </div>
</template>

<script>
import { number } from 'echarts';
import api from "@/api/index.js";

    export default {
        data(){
            return{
                number1: 60,
                number2: 60,
                number3: 60,
                number4: 50,
                number5: 50,
                
            }
        },
        mounted() {
    
  },
    }
</script>
<style lang="less" scoped>
.progressBar{
    width: 200px;
}
li{
   display: flex; 
   justify-content: space-between;
   margin-top: 18px;

   :nth-child(1){
    // margin-left: 10px;
    width: 70px;
    color: rgb(42, 235, 8);
   }

   :nth-child(3){
    margin-right: 10px;
   }
}
.textsty{
    margin-top: 20px;
    margin-left: 10px;
    text-align: left;
    color: #2b90d9;

}
hr{
    width: 80px;
    height: 2px;
    margin-left: 10px;
    margin-top: 3px;
    background-color: #2b90d9;
}
</style>